// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports

export const getSelectedEtapa = createAsyncThunk('selectedEtapa/getSelectedEtapa', async (id) => {
    return {
        id,
    }
})

export const selectedEtapa = createSlice({
    name: 'selectedEtapa',
    initialState: {
        id: null,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getSelectedEtapa.fulfilled, (state, action) => {
            state.id = action.payload.id
        })
    }
})

export default selectedEtapa.reducer
