// ** Redux Imports
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
// ** Axios Imports
import {axiosDb} from "@configs/appConfig";

export const getData = createAsyncThunk(
  "dynamicList/getData",
  async (params) => {
    const response = await axiosDb.get(
      `_table/sct_lista_dinamica?filter=(nome%20like%20%${params.q}%)&include_count=true&limit=${params.perPage}&offset=${params.page}&order=${params.sortColumn}%20${params.sort}&related=sct_lista_dinamica_item%2Csct_lista_dinamica_item_by_ld_id`,
      params
    );
    const everyone = await axiosDb.get(
      `_table/sct_lista_dinamica?include_count=true`,
      params
    );

    return {
      params,
      data: response.data.resource,
      allData: everyone.data.resource,
      totalPages: response.data.meta.count,
    };
  }
);

export const getDataByid = createAsyncThunk(
  "dynamicList/getDataById",

  async (id, { dispatch, getState }) => {
    const response = await axiosDb.get(
      `_table/sct_lista_dinamica/${id}?related=sct_lista_dinamica_item_by_ld_id`
    );

    return { data: response.data };
  }
);

export const createDynList = createAsyncThunk(
  "dynamicList/createList",
  async (data, list, { dispach, getState }) => {
    try {
      const response = await axiosDb
      .post(
        "_table/sct_lista_dinamica",
        {
          resource: data,
        }
      )
      toast.success(t("Dynamic list created successfully"));
    } catch (err) {
      toast.danger(err);
    }
  }
);

export const updateDynList = createAsyncThunk(
  "dynamicList/createList",
  async (data, { dispach, getState }) => {
    try {
      await axiosDb
        .put(
          "_table/sct_lista_dinamica?related=sct_lista_dinamica_item_by_ld_id",
          {
            resource: data,
          }
        )
        .then((response) => {
          return true;
        });

      toast.success(t("Dynamic list uptaded successfully"));
    } catch (err) {
      toast.danger(err);
    }
  }
);
export const deleteInvoice = createAsyncThunk(
  "dynamicList/deleteInvoice",
  async (id, { dispatch, getState }) => {
    try {
      await axiosDb
        .delete(`_table/sct_lista_dinamica_item/${id}?id_field=ld_id`)
        .catch((err) => {
          if (err.response) return toast.error(err);
        });
      await axiosDb.delete(`_table/sct_lista_dinamica/${id}`);
       await dispatch(getData(getState().dynamicList.params));
      return id;
    } catch (err) {
      toast.danger(err);
    }
  }
);

export const duplicateList = createAsyncThunk(
  "dynamicList/duplicate",
  async (id, { dispatch, getState }) => {
    try {
      const duplicateThis = await axiosDb.get(
        `_table/sct_lista_dinamica/${id}?related=sct_lista_dinamica_item_by_ld_id`
      );
      const listaDuplicada =
        duplicateThis.data.sct_lista_dinamica_item_by_ld_id;

      delete duplicateThis.data["id"];
      await axiosDb.post(
        "_table/sct_lista_dinamica?related=sct_lista_dinamica_item_by_ld_id",
        {
          resource: {
            ...duplicateThis.data,
            sct_lista_dinamica_item_by_ld_id: listaDuplicada,
          },
        }
      );

      await dispatch(getData(getState().dynamicList));
      return id;
    } catch (err) {
      toast.danger(err);
    }
  }
);

export const dynamicListSlice = createSlice({
  name: "dynamicList",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.allData = action.payload.allData;
      state.total = action.payload.totalPages;
      state.params = action.payload.params;
    });
    builder.addCase(createDynList.fulfilled, (state, action) => {
      state.data.push(action.payload);
    });
    builder.addCase(getDataByid.fulfilled, (state, action) => {
      state.data = action.payload.data;
    });
    builder.addCase(duplicateList.fulfilled, (state, action) => {
      state.data.push(action.payload);
    });
  },
});

export default dynamicListSlice.reducer;
