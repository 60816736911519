// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useState } from "react";
// ** UseJWT import to get config

export const openModal = createAsyncThunk("projectModalState", (show) => {
    return show
})

export const modalSlice = createSlice({
  name: "projectModalState",
  initialState: {
    show: false,
  },

  extraReducers: builder => {
    builder
      .addCase(openModal.fulfilled, (state, action) => {
        state.show = action.payload
    })
       }
})

export const { handleOpenModal } = modalSlice.actions;

export default modalSlice.reducer;