// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import {axiosDb} from '@configs/appConfig'

export const getCopyNotes = createAsyncThunk('copyNotes/getCopyNotes', async ({etapaId, copyId}) => {
    try {
        const response = await axiosDb.get(`_table/sct_copy_nota`, {
            params: {
              filter: `(eta_id=${etapaId})and(cpy_id=${copyId})`,
            }
        })
        
            return {
                data: response.data.resource,
            }
    } catch (error) {
        return {
            data: []
        }
    }
})

export const copyNotes = createSlice({
    name: 'copyNotes',
    initialState: {
        data: [],
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCopyNotes.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default copyNotes.reducer
