// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import dataHome from '../views/pages/vsl-client/home/store'
import copySlide from '../views/pages/vsl-client/scriptGenerator/store/copySlideStore'
import copyNotes from '../views/pages/vsl-client/scriptGenerator/store/copyNotesStore'
import editSlide from '../views/pages/vsl-client/scriptGenerator/store/editSlideStore'
import selectedEtapa from '../views/pages/vsl-client/scriptGenerator/store/selectedEtapaStore'
import closeModal from '../views/pages/vsl-client/scriptGenerator/store/closeModalStore'
import dynamicList from '../views/pages/vsl-admin/dynamic-list/store'
import copy from '../views/pages/vsl-client/scriptGenerator/store/copyStore'
import modalProject from './projectModal'
import transactions from '../views/pages/vsl-admin/transactions/store'
import stagesWithoutBundler from '../views/pages/vsl-client/scriptGenerator/store/stagesWithoutBundlerStore'
import allCopySlide from '../views/pages/vsl-client/scriptGenerator/store/allCopySlidesStore'
import allCopyNotes from '../views/pages/vsl-client/scriptGenerator/store/allCopyNotesStore'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  dataHome,
  copy,
  copySlide,
  copyNotes,
  allCopySlide,
  allCopyNotes,
  editSlide,
  selectedEtapa,
  closeModal,
  dynamicList,
  modalProject,
  transactions,
  stagesWithoutBundler
};

export default rootReducer;
