// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import {axiosDb} from '@configs/appConfig'

export const getAllCopySlides = createAsyncThunk('allCopySlide/getAllCopySlides', async (copyId) => {

  try {
    const response = await axiosDb.get(`_table/sct_copy_slide?order=${`posicao`}%20${`asc`}`, {
        params: {
            filter: `cpy_id=${copyId}`,
        }
      })
        return {
            data: response.data.resource,
        }
  } catch (error) {
    return {
        data: []
    }
  }
})

export const allCopySlide = createSlice({
    name: 'allCopySlide',
    initialState: {
        data: [],
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAllCopySlides.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default allCopySlide.reducer
