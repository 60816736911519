// ** Axios Imports
import axios from 'axios'

//App config options
const appConfig = {
  env: process.env.REACT_APP_ENV,
  dev: {
    apiPath: 'https://apidev.vslscripts.com.br/api/v2/',
    database: 'database-hml/',
    DreamFactoryApiKey: '36fda24fe5588fa4285ac6c6c2fdfbdb6b6bc9834699774c9bf777f706d05a88'
  },
  prod: {
    apiPath: 'https://outcopy-api.clickmax.dev/api/v2/',
    database: 'database-prod/',
    DreamFactoryApiKey: '36fda24fe5588fa4285ac6c6c2fdfbdb6b6bc9834699774c9bf777f706d05a88'
  }
}


console.log('env-appConfig', appConfig.env)

const Paths = {
  apiPath: appConfig.env === 'dev' ? appConfig.dev.apiPath : appConfig.prod.apiPath,
  databasePath: appConfig.env === 'dev' ? appConfig.dev.apiPath + appConfig.dev.database : appConfig.prod.apiPath + appConfig.prod.database
}

const DfTokens = {
  apiKey: appConfig.env === 'dev' ? appConfig.dev.DreamFactoryApiKey : appConfig.prod.DreamFactoryApiKey,
}

const axiosDb = axios.create({
  baseURL: Paths.databasePath
});

const axiosDf = axios.create({
  baseURL: Paths.apiPath
});
  
export { appConfig, axiosDb, axiosDf, DfTokens, Paths }
  
