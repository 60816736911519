import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
// ** Axios Imports
import { axiosDb } from '@configs/appConfig'
export const getData = createAsyncThunk('transactions/getData', async params => {
    // alterar o nome dos parametros
    const response = await axiosDb.get(`_table/gbl_transacao?filter=(nome_comprador%20like%20%${params.name}%)&include_count=true&limit=${params.perPage}&offset=${params.page}`)
    const everyone = await axiosDb.get(`_table/gbl_transacao?include_count=true`, params)
    return {
        params,
        data: response.data.resource,
        allData: everyone.data.resource,
        totalPages: response.data.meta.count,
    }
})
export const transactionsSlice  = createSlice({
    name: 'transactions',
    initialState: {
        data: [],
        totalPages: 1,
        params: {},
        allData: [],
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.allData = action.payload.allData
            state.totalPages = action.payload.totalPages
            state.params = action.payload.params
        })
    }
})
export default transactionsSlice.reducer