// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports

export const getCloseModal = createAsyncThunk('closeModal/getCloseModal', async ({closeModal}) => {
    return {
      closeModal,
    }
})

export const closeModal = createSlice({
    name: 'editSlide',
    initialState: {
      closeModal: false
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCloseModal.fulfilled, (state, action) => {
            state.closeModal = action.payload.closeModal
        })
    }
})

export default closeModal.reducer
