import {createAsyncThunk, createSlice, createAction} from '@reduxjs/toolkit'


export const getStagesWithoutBundler = createAsyncThunk('stagesWithoutBundler/getStagesWithoutBundler', async (listOfStagesWithoutBundler) => {
  //a maioria dessas funções não são "gets" então devo mudar os nomes
    return {
      listOfStagesWithoutBundler,
    }
})

export const cleaner = createAction('cleaner')

export const stagesWithoutBundler = createSlice({
    name: 'stagesWithoutBundler',
    initialState: {
      listOfStagesWithoutBundler: [],
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getStagesWithoutBundler.fulfilled, (state, action) => {
            state.listOfStagesWithoutBundler = [...state.listOfStagesWithoutBundler, action.payload.listOfStagesWithoutBundler]
        })

        .addCase(cleaner, (state, action) => {
          state.listOfStagesWithoutBundler = []
        })
    }
})

export default stagesWithoutBundler.reducer
