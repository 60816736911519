// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// ** UseJWT import to get config
import useJwt from "@src/auth/jwt/useJwt";
import { axiosDb, axiosDf } from "@configs/appConfig";

import { getUserData } from "@src/auth/utils";
import { toast } from "react-toastify";
import { Fragment } from "react";

const config = useJwt.jwtConfig;

const userData = getUserData();
export const getUserVsl = createAsyncThunk(
  "authentication/getUserVsl",
  async (id) => {
    const response = await axiosDb.get(
      `_table/gbl_usuario/${id}?id_field=df_id&related=sys_perfil_by_per_id`
    );

    return response;
  }
);

export const updateUser = createAsyncThunk(
  "authentication/updateUser",
  async (data) => {
    const name = data.first_name + " " + data.last_name;
    const display_name = data.first_name + " " + data.last_name;

    await axiosDb.put(`_table/gbl_usuario/${id}`, {
      resource: [
        {
          id: parseInt(userData.id),
          nome: name,
        },
      ],
    });
  }
);

export const updatePassword = createAsyncThunk(
  "authentication/updatePassword",
  async (data) => {
    await axiosDf
      .post("/user/password?login=true&reset=true", {
        old_password: data.old_password,
        new_password: data.new_password,
        email: data.email,
      })
      .then((response) => {
        userData.session_token = response.data.session_token;

        if (response.data.success === true) {
          localStorage.setItem("userData", JSON.stringify(userData));
          alert("sucesso");
        }
      })
      .catch((error) => {
        console.error("Error during service worker registration:", error);
        alert("fracasso");
      });
  }
);

const initialUser = () => {
  const item = window.localStorage.getItem("userData");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser(),
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload;
      state[config.storageTokenKeyName] =
        action.payload[config.storageTokenKeyName];
      state[config.storageRefreshTokenKeyName] =
        action.payload[config.storageRefreshTokenKeyName];
      localStorage.setItem("userData", JSON.stringify(action.payload));
      localStorage.setItem(
        config.storageTokenKeyName,
        JSON.stringify(action.payload.accessToken)
      );
      localStorage.setItem(
        config.storageRefreshTokenKeyName,
        JSON.stringify(action.payload.refreshToken)
      );
    },
    handleLogout: (state) => {
      state.userData = {};
      state[config.storageTokenKeyName] = null;
      state[config.storageRefreshTokenKeyName] = null;
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem("vslData");
      localStorage.removeItem("userData");
      localStorage.removeItem(config.storageTokenKeyName);
      localStorage.removeItem(config.storageRefreshTokenKeyName);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.userData = action.payload;
    });
    builder.addCase(getUserVsl.fulfilled, (state, action) => {
      localStorage.setItem("vslData", JSON.stringify(action.payload.data));
    });
  },
});

export const { handleLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;
