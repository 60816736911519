// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import {axiosDb} from '@configs/appConfig'

export const getCopySlide = createAsyncThunk('copySlide/getCopySlide', async ({etapaId, copyId}) => {


  try {
    const response = await axiosDb.get(`_table/sct_copy_slide?order=${`posicao`}%20${`asc`}`, {
        params: {
            filter: `(eta_id=${etapaId})and(cpy_id=${copyId})`,
        }
      })
    
        return {
            data: response.data.resource,
        }
  } catch (error) {
    return {
        data: []
    }
  }
})

export const copySlide = createSlice({
    name: 'copySlide',
    initialState: {
        data: [],
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCopySlide.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default copySlide.reducer
