// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
// ** AppConfig
import { axiosDb, axiosDf } from "@configs/appConfig";
import { todayDate, getUserData } from "@utils"

export const getScriptsMarketplace = createAsyncThunk("data/getScriptsMarketplace", async (params) => {
  try {
    const scripts = await axiosDb.get("_table/sct_script", {
      params: {
        related: 'gbl_usuario_by_usu_id_criacao',
        include_count: true,
        order: 'graus_temperatura DESC AND criado_em DESC',
        filter: 'status==true',
        limit: 8
      }
    });
    return {
      params,
      scripts: scripts.data.resource,
      totalPagesScripts: scripts.data.meta.count,
    };
  } catch (e) {
    return console.error(e.message);
  }
});

export const getMyScripts = createAsyncThunk("data/getMyScripts", async (params) => {
  try {
    const scripts = await axiosDb.get(
      "_table/v_scripts_usuario",
      {
        params: {
          filter: `usuario=${getUserData().id}`
        }
      }
    );

    return {
      scripts: scripts.data.resource,
    };
  } catch (e) {
    return console.error(e.message);
  }
});

export const getAllScript = createAsyncThunk("data/getAllScript", async (params) => {
  try {
    const scripts = await axiosDb.get(
      "_table/sct_script?related=gbl_usuario_by_usu_id_criacao&include_count=true&order=alterado_em%20ASC&filter=status=true"
    );
    return {
      params,
      scripts: scripts.data.resource,
      totalPagesScripts: scripts.data.meta.count,
    };
  } catch (e) {
    return console.error(e.message);
  }
});

export const getLastProjects = createAsyncThunk("data/getLastProjects", async () => {
  const response = await axiosDb.get(
    `_table/gbl_projeto?filter=(usu_id_criacao=${getUserData().id})%20AND%20(ativo=true)&include_count=true&limit=3&order=alterado_em%20DESC&related=sct_copy_by_proj_id%2Csct_script_by_sct_copy`
  );

  return {
    data: response.data.resource,
    totalPages: response.data.meta.count
  }
})

export const getMyProjects = createAsyncThunk("data/getMyProject", async (text) => {
  const responseActive = await axiosDf.post(`graphql`, {
    query: `
      query getProjectsByName {
        gbl_projeto(where: {usu_id: {_eq: "${
          getUserData().id
        }"}, nome: {_ilike: "%${text}%"}, ativo: {_eq: true}}) {
          id
          nome
          alterado_em
          ativo
          cli_id
          criado_em
          foto_capa_url
          usu_id
          usu_id_alteracao
          usu_id_criacao
          sct_copies {
            id
            nome
            alterado_em
            cli_id
            criado_em
            ppm
            proj_id
            scpt_id
            usu_id_criacao
            usu_id_alteracao
          }
        }
      }
    `,
  });
  const responseActiveTotal = await axiosDb.get(
    `_table/gbl_projeto?filter=(usu_id_criacao=${getUserData().id})%20AND%20(ativo=true)&include_count=true`
  );
  const responseInactive = await axiosDf.post(`graphql`, {
    query: `
      query getProjectsByName {
        gbl_projeto(where: {usu_id: {_eq: "${
          getUserData().id
        }"}, nome: {_ilike: "%${text}%"}, ativo: {_eq: false}}) {
          id
          nome
          alterado_em
          ativo
          cli_id
          criado_em
          foto_capa_url
          usu_id
          usu_id_alteracao
          usu_id_criacao
        }
      }
    `,
  });
  const responseInactiveTotal = await axiosDb.get(
    `_table/gbl_projeto?filter=(usu_id_criacao=${getUserData().id})%20AND%20(ativo=false)&include_count=true`
  );
  return {
    projectsActive: responseActive.data.data.gbl_projeto,
    projectsActiveTotal: responseActiveTotal.data.meta.count,
    projectsInactive: responseInactive.data.data.gbl_projeto,
    projectsInactiveTotal: responseInactiveTotal.data.meta.count,
  };
})

export const getProjectById = createAsyncThunk(
  "data/getProjectById",
  async (id) => {
    const clean = id === 0 
    const response = await axiosDb.get(
      `_table/gbl_projeto?related=sct_copy_by_proj_id%2Csct_script_by_sct_copy&filter=(id=${id})%20AND%20(usu_id_criacao=${getUserData().id})`
      );
    return {
      project: response.data?.resource[0] || [],
      total: response.data?.resource[0]?.sct_copy_by_proj_id || 0,
      clean
    };
  }
);

export const newProject = createAsyncThunk(
  "data/newProject",
  async (data, { dispatch, getState }) => {
    try {
      axiosDb
        .post("_table/gbl_projeto", {
          resource: data,
        })
        .then((response) => {
          return response;
        });

      await dispatch(getLastProjects(getState().dataHome.params));
      await dispatch(getMyProjects(getState().dataHome.params));
      toast.success(t("Project created successfully"));
    } catch (error) {
      return error;
    }
  }
);

export const deleteProject = createAsyncThunk("data/deleteProject",
  async (id, { dispatch, geState }) => {

    await axiosDb.delete(`_table/sct_copy/${id}?id_field=proj_id`).catch((err) => {
      return err
    });
    try {
      await axiosDb
        .delete(`_table/gbl_projeto/${id}`)
        .then((response) => {
          return response;
        });

      await dispatch(getLastProjects(getState().dataHome.params));
    } catch (error) {
      return error;
    }

  }
)

export const newSctCopy = createAsyncThunk(
  "data/newSctCopy",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      axiosDb
        .post("_table/sct_copy", {
          resource: { ...data, criado_em: todayDate()  }
        })
        .then((response) => {
          toast.success('Script criado com sucesso');
          dispatch(getProjectById(data.proj_id))
          return response;
        });
    } catch (error) {
      toast.error('Houver um erro ao criar o seu script');
      return rejectWithValue(error.response)
    }
  }
);

export const duplicateSctCopy = createAsyncThunk(
  "data/newSctCopy",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      axiosDb
        .post("_table/sct_copy", {
          resource: { ...data, criado_em: todayDate() }
        })
        .then((response) => {
          dispatch(getProjectById(data.proj_id))
          toast.success("Script duplicado com sucesso");
          return response;
        });
    } catch (error) {
      toast.error('Houver um erro ao duplicar o seu script');
      return rejectWithValue(error.response)
    }
  }
);

export const updateProject = createAsyncThunk(
  "data/updateProject",
  async (data, { dispatch, getState }) => {
    await axiosDb.put(`_table/gbl_projeto/${data.id}`, data)
      .then();

    await dispatch(getProjectById(data.id));
  }
);

export const deleteSctCopy = createAsyncThunk(
  "data/deleteSctCopy",
  async (id, { dispatch, getState }) => {
    await axiosDb.delete(`_table/sct_copy/${id}`);
    return id;
  }
);

export const updateSctCopy = createAsyncThunk(
  "data/updateSctCopy",
  async (data, { dispatch, getState }) => {
    try {
      await axiosDb.put(`_table/sct_copy/${data.id}`, data)

      toast.success("Script atualizado com sucesso");
      await dispatch(getProjectById(getState().dataHome.project.id));
    } catch (err) {
      toast.error('Houver um erro ao atualizar o seu script');
    }

  }
);


export const dataSlice = createSlice({
  name: "data",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    scripts: [],
    totalPagesScripts: 0,
    project: {},
    error: {},
    projects: [],
    clean: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getScriptsMarketplace.fulfilled, (state, action) => {
      state.params = action.payload?.params;
      state.scripts = action.payload?.scripts;
      state.totalPagesScripts = action.payload?.totalPagesScripts;
    });
    builder.addCase(getLastProjects.fulfilled, (state, action) => {
      state.data = action?.payload?.data;
      state.total = action.payload?.totalPages;
    })
    builder.addCase(getProjectById.fulfilled, (state, action) => {
      state.project = action.payload.project;
      state.total = action.payload.total;
      state.clean = action.payload.clean
    });
    builder.addCase(getMyProjects.fulfilled, (state, action) => {
      state.projectsActive = action.payload.projectsActive;
      state.projectsActiveTotal = action.payload.projectsActiveTotal;
      state.projectsInactive = action.payload.projectsInactive;
      state.projectsInactiveTotal = action.payload.projectsInactiveTotal;
    });
    builder.addCase(getMyScripts.fulfilled, (state, action) => {
      state.params = action.payload?.params;
      state.scripts = action.payload?.scripts;
      state.total = action.payload?.total;
    });
    builder.addCase(newProject.fulfilled, (state, action) => {
      state.data.push(action.payload);
    });
    builder.addCase(newSctCopy.fulfilled, (state, action) => {
      state.data = action.payload
    })

  },
});

export default dataSlice.reducer;
