// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports

export const getEditSlide = createAsyncThunk('editSlide/getEditSlide', async ({id, isEditing, text}) => {
    return {
        isEditing,
        id,
        text,
    }
})

export const editSlide = createSlice({
    name: 'editSlide',
    initialState: {
        id: null,
        isEditing: false,
        text: "",
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getEditSlide.fulfilled, (state, action) => {
            state.isEditing = action.payload.isEditing
            state.id = action.payload.id
            state.text = action.payload.text
        })
    }
})

export default editSlide.reducer
