// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** Axios Imports
import { axiosDb } from '@configs/appConfig'

export const getCopy = createAsyncThunk('copy/getCopy', async (id) => {
    const response = await axiosDb.get(`_table/sct_copy`, {
        params: {
            filter: `id=${id}`,
        }
    })

    return {
        data: response.data.resource,
    }
})

export const copy = createSlice({
    name: 'copy',
    initialState: {
        data: [],
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCopy.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default copy.reducer
